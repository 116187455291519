@import "../../global/globalStyle.scss";
@import "../../global/globalColorcss.scss";
.footer {
  // max-width: 1400px;
  margin: 0 auto;
  top: auto;
  bottom: 0;
  font-family: var(--font_family);
  @media (max-width: 600px) {
    // max-width: 1800px;
    height: 356px;
  }
  .version_indicator {
    color: var(--grey-shade9d);
    font-size: 13px;
    margin-left: 13px;
  }
  .footerInner {
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
    height: 270px;
    @media (max-width: 1024px) {
      height: 340px;
      .homeinternetlink {
        color: var(--white);
        text-decoration: none;
      }
    }

    @media (max-width: 1980px) {
      // max-width: 1800px;
      max-width: calc(100% - 40px);
    }

    // @media (max-width: 1680px) {
    //     max-width: 1600px;
    // }
    // @media (max-width: 1536px) {
    //     max-width: 1440px;
    // }
    // @media (max-width: 1440px) {
    //     max-width: 1400px;
    // }
    // @media (max-width: 1367px) {
    //     max-width: 1300px;
    // }
    // @media (max-width: 1280px) {
    //     max-width: 1200px;
    // }
    // @media (max-width: 1024px) {
    //     max-width: 960px;
    // }
    // @media (max-width: 768px) {
    //     max-width: 700px;
    // }
  }

  // position: absolute;
  // bottom: 0%;
  // width: 100%;
  .links {
    width: 100%;
    display: flex;
    .container {
      margin-top: 2%;
    }
    .headerText {
      font-size: 14px;
      font-weight: var(--font_weight_4);
      color: var(--text_color);
    }
    .subLinks {
      color: var(--white);
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: var(--font_weight_1);
      margin: 10px 0 0;
      cursor: pointer;
      @media (max-width: 600px) {
        width: 90px;
      }
      // display: flex;
      // align-items: center;
    }
    .links1 {
      //   width: 25%;
      width: 15%;
      padding-top: 83px;
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .moreLinks {
      display: flex;
      margin-left: 10%;
      width: 100%;
      padding-top: 73px;

      .moreLinks1 {
        display: flex;
        width: 100%;
      }
      .moreLinks2 {
        display: flex;
        width: 35%;
      }

      .moreLinks3 {
        display: flex;
        width: 25%;
      }
      .moreLinks4 {
        display: flex;
        width: 25%;
      }
    }
    .links {
      width: 100%;
    }
    .links2 {
      margin: 0 2%;
    }
    .links3 {
      width: 100%;
    }
    .links4 {
      width: 34%;
    }
    .links5 {
      width: 100%;
    }
    .links6 {
      width: 56%;
    }
    .links7 {
      width: 56%;
    }
    .links8 {
      width: 44%;
    }
    .links9 {
      width: 50%;
    }
    .reachImg {
      width: 178px;
      height: 52px;
      @media (max-width: 600px) {
        width: 120px;
        height: 35px;
        margin: 10px 0px;
      }
    }

    .appsLinkweb {
      display: flex;
      width: 15%;
      padding-top: 73px;
      margin-right: 5%;
      .icons {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .Line {
    // max-width: 1400px;
    height: 1px;
    margin: 0px 0 30px 0;
    border-bottom: solid 1px #d1d1d1;
    @media (max-width: 600px) {
      margin: 0px -20px 30px -20px;
    }
  }
  .Line1 {
    // max-width: 1400px;
    height: 1px;
    margin: 30px 0;
    border-bottom: solid 1px #d1d1d1;
    @media (max-width: 600px) {
      margin: 30px -20px 30px -20px;
    }
  }

  .bootomLinks {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .copyright {
      font-size: 13px;
      font-weight: var(--font_weight_1);
      color: var(--silver-chalice);
    }
    .rightFooterBottomDiv {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .assuredCardFooter img {
    width: 145px;
    height: 35px;
    margin: 0 10px;
  }
  .trustpilotFooter1,
  .assuredCardFooter1 {
    display: none;
  }

  .newTag {
    width: 35px;
    margin-left: 5px;
    // margin-bottom: -3px;
  }

  @media (max-width: 1024px) {
    width: calc(100% - 40px);
    // max-width: 960px;

    .links {
      .reachImg {
        margin-right: 20px;
      }
      .links1 {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        padding: 0;
        width: 55%;

        .appsLinkmobile {
          display: none;
        }
      }
      padding: 0 0px;
      .moreLinks {
        padding: 0;
        margin-left: 0;
        margin-top: 45px;
        width: 95%;
        flex-direction: row;
        .moreLinks1,
        .moreLinks2,
        .moreLinks3,
        .moreLinks4 {
          width: 100%;
          flex-direction: column;
          .links2 {
            width: 100%;
          }
          .links3 {
            width: 100%;
          }
          .links4 {
            width: 100%;
          }
          .links5 {
            width: 100%;
          }
          .links6 {
            width: 100%;
          }
          .links7 {
            width: 100%;
          }
          .links8 {
            width: 100%;
          }
          .links9 {
            width: 100%;
          }
        }
      }
      .appsLinkweb {
        padding-top: 55px !important;
        margin-right: 0 !important;
      }
    }
    .copyright-text {
      margin-top: 20px;
    }
    .disclaimer-text {
      margin-top: 20px;
    }
  }
  @media (max-width: 768px) {
    .bootomLinks {
      padding: 0 10px;
      margin-bottom: 10px;
      .trustpilotFooter,
      .assuredCardFooter {
        display: none;
      }
    }
    .bootomIconFoterSmall {
      display: flex;
      justify-content: flex-start;
      .trustpilotFooter1 {
        display: flex;
        justify-content: flex-start;
        margin: 20px 0px 20px -10px;
      }
      .assuredCardFooter1 {
        display: flex;
        justify-content: flex-start;
        img {
          margin: 10px 0px 0px 10px;
          width: 145px;
          height: 35px;
        }
      }
    }

    .footerInner {
      width: calc(100% - 40px);
    }
    .links {
      .links1 {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        padding: 0;

        .appsLinkmobile {
          // display: none;
        }
      }
      padding: 0 0px;
      .moreLinks {
        padding: 0;
        margin-left: 0;
        margin-top: 45px;
        flex-direction: row;
        .moreLinks1,
        .moreLinks2,
        .moreLinks3,
        .moreLinks4 {
          width: 100%;
          flex-direction: column;
          .links2 {
            width: 100%;
          }
          .links3 {
            width: 100%;
          }
          .links4 {
            width: 100%;
          }
          .links5 {
            width: 100%;
          }
          .links6 {
            width: 100%;
          }
          .links7 {
            width: 100%;
          }
          .links8 {
            width: 100%;
          }
          .links9 {
            width: 100%;
          }
        }
      }
      .appsLinkweb {
        padding-top: 55px !important;
        margin-right: 0 !important;
      }
    }
    .copyright-text {
      margin-top: 20px;
    }
    .disclaimer-text {
      margin-top: 20px;
    }
  }

  @media (max-width: 600px) {
    .footerInner {
      width: calc(100% - 40px);

      .homeinternetlink {
        color: var(--white);
        text-decoration: none;
      }
    }
    .links {
      display: flex;
      flex-direction: column;
      .links1 {
        padding: 15px 0px;
        margin: 0 !important;
        display: flex;
        justify-content: space-between;
      }
      padding: 0 0px;
      .moreLinks {
        padding: 0;
        margin-left: 15px;
        flex-direction: row;
        margin-top: 0px;

        .moreLinks1,
        .moreLinks2,
        .moreLinks3,
        .moreLinks4 {
          width: 100%;
          flex-direction: column;
          .links2 {
            width: 75%;
          }
          .links3 {
            width: 100%;
          }
          .links4 {
            width: 100%;
          }
          .links5 {
            width: 100%;
          }
          .links6 {
            width: 100%;
          }
          .links7 {
            width: 100%;
          }
          .links8 {
            width: 100%;
          }
          .links9 {
            width: 100%;
          }
        }
        // .moreLinks1 {
        //   width: 100%;
        //   flex-direction: column;
        //   .links2 {
        //     width: 75%;
        //   }
        //   .links3 {
        //     width: 100%;
        //   }
        //   .links4 {
        //     width: 100%;
        //   }
        //   .links5 {
        //     width: 100%;
        //   }
        //   .links6 {
        //     width: 100%;
        //   }
        //   .links7 {
        //     width: 100%;
        //   }
        // }
      }
      .appsLinkweb {
        display: none;
      }
      .appsLinkmobile {
        .icons {
          margin-right: 10px !important;
        }
      }
    }
    .bootomIconFoterSmall {
      flex-direction: column-reverse;
    }
  }

  .appsLinkmobile {
    display: none;
    @media (max-width: 600px) {
      display: block !important;
      display: flex !important;
      padding: 0 !important;
      margin: 5px 50px !important;
    }

    @media (max-width: 1024px) {
      display: none;
    }
    @media (max-width: 768px) {
      display: block;
      display: flex;
      padding: 0;
    }
  }

  .copyright-text {
    font-size: 13px;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.1px;
    text-align: center;
    color: var(--white);
    font-family: var(--font_family_Medium);

    @media (max-width: 600px) {
      margin-top: 15px;
    }
  }
  .disclaimer-text {
    margin: 40px;
    font-size: 13px;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    text-align: left;
    color: var(--white);
    font-family: var(--font_family_Medium);
    @media (max-width: 600px) {
      margin: 15px 0px;
    }
  }
}
.footer.MuiAppBar-colorPrimary {
  background-color: var(--white);
}
.footer.MuiPaper-elevation4 {
  box-shadow: none;
}
.footer.MuiAppBar-positionAbsolute {
  top: auto !important;
  bottom: 0;
  @media (max-height: 900px) {
    position: unset;
  }
}

.SimpleFooter {
  border-top: solid 1px #9696964d;
  padding: 30px;
  .SimpleMainDiv {
    display: flex;
    justify-content: space-between;
  }
  .appsLink {
    display: flex;
    padding: 0;
    .icons {
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .copyright {
    font-family: SharpSans;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--grey-shade87);
  }
  .SimpleFooterLeft {
    display: flex;
    .trustpilotFooter1 {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  .trustpilotFooterSimpleMobile {
    display: none;
    @media (max-width: 600px) {
      display: flex;
      justify-content: flex-start;
      margin: 10px 0 0 0;
    }
  }
}
.web {
  display: unset;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 600px) {
    display: none;
  }
}
.mobile {
  display: none;
  @media (max-width: 1024px) {
    display: unset;
    width: 100%;
  }
}
