@import "../global/globalColorcss.scss";

.mainRoutes {
  // padding-top: 80px;
  // margin: auto 25px;
  margin: 71px auto 0 auto;
  max-width: 1680px;
  // min-width: 978px;

  @media (max-width: 1920px) {
    max-width: 1440px;
  }
  @media (max-width: 1680px) {
    max-width: 1680px;
    // margin: auto 25px;
  }
  @media (max-width: 1536px) {
    max-width: 1536px;
  }
  @media (max-width: 1440px) {
    max-width: 1440px;
  }

  @media (max-width: 1024px) {
    max-width: 1024px;
    // min-width: 768px;
    margin: auto 0px;
  }
  @media (max-width: 768px) {
    max-width: 768px;
    // min-width: 425px;
    margin: auto 0px;
  }

  @media (max-width: 425px) {
    // padding-top: 60px;
    max-width: 425px;
    // min-width: 320px;
  }
  @media (max-width: 600px) {
    margin: auto;
    // margin-top: 50px !important;
  }
}

.mainRoutesLanding {
  // padding-top: 180px;
  padding-top: 67px !important;
  margin: 0 auto;
  max-width: 1680px;

  @media (max-width: 1920px) {
    max-width: 1920px;
  }
}

.landingPageRoutes {
  padding: 0;
}

/* Notification */
.rnc__notification-container--top-full {
  .rnc__notification {
    width: 100% !important;
    text-align: center !important;
  }
}
.rnc__notification-item--info {
  background-color: var(--info_notification) !important;
  border-left: 8px solid var(--info_notification) !important;
}
.rnc__notification-item--info .rnc__notification-close-mark {
  background-color: var(--info_notification) !important;
}

.rnc__notification-item--danger {
  background-color: var(--danger_notification) !important;
}
.rnc__notification-item--danger .rnc__notification-close-mark {
  background-color: var(--danger_notification) !important;
}
