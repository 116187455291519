:root {
  --primary_color: #10ace3;
  --white: #fff;
  --whitesmoke: #f5f5f5;
  --success: #3a8580;
  --default: #e8e8e8;
  --notificaton_danger: #962504;
  --danger: #d0021b;
  --red-color_2: #c30e0e;
  --red-color_3: #912f2f;
  --red-color_4: #f44336;
  --info: #996699;
  --primary_brand_color: #12abe3;
  --primary_brand_color_light: #10ace3;
  --lavender: #a976a2;
  --text_color: #313131;
  --text_color_1: #2c2c2c;
  --text_color_2: #383838;
  --text_color_3: #212529;
  --text_color_4: #414141;
  --text_color_5: #434343;
  --text_color_6: #494949;
  --tab_color_1: #414042;
  --grey_shade: #747474;
  --grey_shade_2: #bebebe;
  --grey_shade_3: #7e7e7e;
  --concrete-grey: #f3f3f3;
  --honey: #eeab18;
  --dusk: #48688f;
  --dusty-orange: #ffc40c;
  --grey: #575757;
  --lightgrey: #f9f9f9;
  --lightgrey_2: #f0f0f0;
  --lightgrey_3: #f2f2f2;
  --lightgrey_4: #eee;
  --lightgrey_5: #999;
  --medium-shade-grey: #404040;
  --medium-grey-shade_2: #515151;
  --border_dictator: #10ace3;
  --primary_background: #0072ab;
  --lite_background: #e1f0f5;
  --gray_background: #e7e7e7;
  --cool_blue: #4a7bb4;
  --warm_grey: #9c9c9c;
  --danger_notification: #ab0800;
  --info_notification: #0072ab;
  --subtext-color: #898989;
  --subtect-color_1: #666;
  --black: #000;
  --accordion-body_color: #686868;
  --mute_color: #e6e6e6;
  --complete: #6d6875;
  --title-color_1: #4d4d4d;
  --title-color_2: #525252;
  --radio-color: #bbbbbb;
  --card-color: #dee2e6;
  --header-color_1: #121629;
  --subheader-color_1: #585757;
  --support-color: #1aabe0;
  --light-silver_color: #d8d8d8;
  --ford-grey-color: #979797;
  --grey33-color: #545454;
  --grey90-color: #e5e5e6;
  --water-color: #d1eef9;
  --bleached_aqua: #3c8f8c;
  --bleached_aqua_2: #3d9997;
  --lavendar_color: #eaeaf0;
  --grey-seashell: #f2f1f1;
  --grey81-color: #cfcfcf;
  --grey98-color: #fafafa;
  --grey77-color: #c4c4c4;
  --grey-shade87: #878787;
  --grey-shade9d: #8c909d;
  --darkgrey_shade: #262626;
  --cyan-blue-shade: #75787b;
  --medium-cyan-shade: #9cc2bf;
  --medium-cyan-shade_2: #80d5f2;
  --cyan-blue-shade_2: #777;
  --dark-slate-blue: #378781;
  --blue-color: #0000ff;
  --chinese-silver: #ccc;
  --light-brown: #b6aca2;
  --green-shade: #1a342f;
  --cruise-blue: #33746e;
  --light-blue-shade: #5e85b2;
  --silver-chalice: #a5a5a5;
  --dark-charcoal: #333;
}
