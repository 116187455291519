@import "../../global/globalStyle.scss";
@import "../../global/globalColorcss.scss";
.TextField {
  width: 100%;

  .MuiFilledInput-input {
    padding: 15px 15px 15px;
    background: #fff;
    border-radius: 6px;
    color: var(--text_color);
    font-family: var(--font_family_Medium);
  }
  .MuiInputLabel-filled {
    z-index: 1;
    transform: translate(15px, 18px) scale(1);
    color: #747474;

    font-family: var(--font_family_Medium);
    @media (max-width: 600px) {
      text-overflow: ellipsis;
      width: 270px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .MuiFilledInput-root:hover {
    border-color: var(--primary_brand_color);
  }
  .MuiFilledInput-root {
    border-radius: 6px;
    border: solid 1px #bbbbbb;
    background-color: #ffffff;
  }
  .MuiFilledInput-root.Mui-error {
    border-color: var(--danger);
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: -1;
  }
  .MuiFilledInput-underline:hover:before,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border: none;
  }
  .MuiFilledInput-underline.Mui-disabled:before {
    border: none;
  }
  .MuiInputBase-input.Mui-disabled {
    background-color: #eee;
  }
}
.TextFieldEarthlink {
  width: 100%;

  .MuiFilledInput-input {
    padding: 15px 15px 15px;
    background: #fff;
    border-radius: 6px;
    color: var(--text_color);
    font-family: var(--font_family_Medium);
  }
  .MuiInputLabel-filled {
    z-index: 1;
    transform: translate(15px, 18px) scale(1);
    color: #747474;

    font-family: var(--font_family_Medium);
    @media (max-width: 600px) {
      text-overflow: ellipsis;
      width: 270px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .MuiFilledInput-root:hover {
    border-color: var(--dusty-orange);
  }
  .MuiFilledInput-root {
    border-radius: 6px;
    border: solid 1px #bbbbbb;
    background-color: #ffffff;
  }
  .MuiFilledInput-root.Mui-error {
    border-color: var(--danger);
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: -1;
  }
  .MuiFilledInput-underline:hover:before,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border: none;
  }
  .MuiFilledInput-underline.Mui-disabled:before {
    border: none;
  }
  .MuiInputBase-input.Mui-disabled {
    background-color: #eee;
  }
}
