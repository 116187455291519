.checkCoverageOuter {
  height: 100%;
  padding: 25px 50px;
  @media (max-width: 600px) {
    height: 100%;
    padding: 25px 35px;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    height: 100%;
    padding: 50px 50px;
  }
  background-color: var(--primary_background);

  .TextField .MuiFilledInput-root {
    width: 400px;
    border: 1px solid var(--white);
    @media (max-width: 600px) {
      width: 90%;
    }
  }
  .TextField .MuiFilledInput-input {
    background: var(--primary_background);
    color: var(--white);
  }
  .TextField .MuiInputLabel-filled {
    color: var(--white);
    @media (max-width: 600px) {
      overflow: inherit;
    }
  }

  .MuiButton-startIcon {
    margin-left: 8px !important;
    .MuiSvgIcon-root {
      font-size: 43px !important;
      font-weight: var(--font_weight_0);
    }
  }

  .coverageCheckHeading {
    font-size: 30px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: var(--white);
    font-family: var(--font_family_Demibold);
    @media (max-width: 1200px) {
      font-size: 22px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 1024px) {
      font-size: 22px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 600px) {
      font-size: 22px;
      display: flex;
      text-align: center;
    }
  }
  .coverageCheckSubHeading {
    font-size: 26px;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
    color: var(--white);
    margin: 14px 0;
    font-family: var(--font_family_Medium);
    @media (max-width: 1200px) {
      font-size: 18px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 600px) {
      font-size: 18px;
      display: flex;
      text-align: center;
    }
  }
  .addressHolder {
    width: 400px;
    display: flex;
    @media (max-width: 600px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      .MuiButton-root {
        margin-left: 115px !important;
      }
    }
    .MuiButton-root {
      background-color: var(--primary_background);
      color: var(--white);
      border: 1px solid var(--white);
      height: 55px;
      margin-left: 10px;
    }
    .submitBtn {
      @media (max-width: 600px) {
        width: 45px;
        display: flex;
        flex-direction: column;
        margin-left: 40% !important;
        // .MuiButton-root {
        //   margin-left: 115px !important;
        // }
        span {
          margin: 0px !important;
        }
      }
    }
  }

  .coverageFinalText {
    img {
      margin-right: 10px;
    }
    .signalNote {
      margin-left: 48px;
    }
    font-family: var(--font_family);
    font-size: 18px;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
    font-family: var(--font_family);
    color: var(--white);
    @media (max-width: 1200px) {
      font-size: 18px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
    }

    @media (max-width: 600px) {
      font-size: 16px;
      text-align: left;
    }
  }
  .coverageButtons {
    margin: 10px 45px;
    flex-direction: row;
    @media (max-width: 600px) {
      text-align: center;
    }
    .MuiButton-text {
      color: var(--white);
      font-family: var(--font_family);
      font-size: 14px;
      font-weight: var(--font_weight_2);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-right: 20px;
      width: 200px;
      padding: 10px 0px;
      @media (max-width: 600px) {
        width: 100%;
        margin-top: 15px;
      }
    }

    Button {
      border: 1px solid var(--white);
    }
  }

  .coverageError {
    color: var(--danger);
    margin-top: 0px;
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }
}
