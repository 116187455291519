@import "../../global/globalColorcss.scss";
@import "../../global/globalStyle.scss";


  .BrandBillLine {
    width: 85px;
    height: 4px;
    border-radius: 2.5px;
    background-color: var(--dusty-orange);
    margin: 10px auto 15px auto !important;
  }