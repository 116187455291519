@import "../../global/globalStyle.scss";
@import "../../global/globalColorcss.scss";
.ButtonPrimary {
  font-family: var(--font_family_Semibold) !important;
  // background-color: var(--white) !important;
  font-size: 16px !important;
  // color: var(--white) !important;
  width: 100%;
  height: 50px;
  // border-radius: 25px !important;
  text-align: center !important;
  text-transform: capitalize !important;
  margin: 0px 0px !important;
  padding: 13px 23px 13px 23px !important;
  min-width: fit-content !important;
  opacity: 1;
  border: 1px solid var(--primary_color) !important;
}
.ButtonSecondary {
  font-family: var(--font_family_Semibold) !important;
  // background-color: var(--white);
  font-size: 16px !important;
  color: var(--white) !important ;
  // color: var(--cool_blue) !important;
  width: 100%;
  height: 50px;
  border-radius: 25px !important;
  text-align: center !important;
  text-transform: capitalize !important;
  margin: 0px 0px !important;
  padding: 13px 23px 12px 23px !important;
  min-width: fit-content !important;
  opacity: 1;
  border: 1px solid var(--primary_color) !important;
  // box-sizing: border-box;
}
.disabledBtn {
  opacity: 0.5;
}
