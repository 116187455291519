@import "../../global/globalColorcss.scss";
@import "../../global/globalStyle.scss";
.simpleHeaderMain {
  .fontStyleHeader {
    font-family: var(--font_family_Semibold);
  }

  .MuiAppBar-colorPrimary {
    background-color: var(--white);
  }

  .headerContent {
    height: 71px;
    .headerLogo {
      padding: 0px 0px 0px 40px;
      width: 140px;
      height: 41px;
      cursor: pointer;
    }
    .headerInnerDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1680px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      .middleDiv {
        font-size: 22px;
        font-weight: var(--font_weight_4);
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--text_color);
      }
      .lastDiv {
        padding-right: 40px;
        display: flex;
        align-items: center;
        .ButtonSecondary {
          border: none !important;
        }
        .help {
          color: var(--grey_shade);
          font-family: var(--font_family_Semibold);
          font-weight: normal;
          margin-right: 30px;
          font-size: 14px;
          margin-top: 5px;
          cursor: pointer;
        }
        .innerLast {
          display: flex;
          align-items: center;
          .MuiSvgIcon-root {
            fill: var(--primary_brand_color);
            height: 30px;
            width: 30px;
            margin-right: 5px;
          }
          .name {
            text-transform: uppercase;
            font-family: var(--font_family_Semibold);
            font-size: 14px;
            font-weight: var(--font_weight_2);
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--text_color_2);
          }
        }
      }

      @media (max-width: 1920px) {
        max-width: 1440px;
      }
      @media (max-width: 1680px) {
        max-width: 1680px;
      }
      @media (max-width: 1536px) {
        max-width: 1536px;
      }
      @media (max-width: 1440px) {
        max-width: 1440px;
      }
      @media (max-width: 1366px) {
        max-width: 1366px;
      }
      @media (max-width: 1280px) {
        max-width: 1280px;
      }
      @media (max-width: 1024px) {
        max-width: 1024px;
      }

      @media (max-width: 1024px) {
        display: none;
        height: unset;
      }
    }

    @media (max-width: 1920px) {
      max-width: 1920px;
    }
    @media (max-width: 1536px) {
      max-width: 1536px;
    }
    @media (max-width: 1440px) {
      max-width: 1440px;
    }
    @media (max-width: 1366px) {
      max-width: 1366px;
    }
    @media (max-width: 1280px) {
      max-width: 1280px;
    }
    @media (max-width: 1024px) {
      max-width: 1024px;
    }

    @media (max-width: 1024px) {
      display: none;
      height: unset;
    }
  }
  .headerContentMobile {
    display: none;

    .headerLogoMobile {
      padding: 0 0 0 15px;
      width: 120px;
      height: 35px;
      cursor: pointer;
      @media (max-width: 600px) {
        padding: 0 0 0 10px;
      }
    }
    .headerContentMobileinner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 71px;
    }
    @media (max-width: 1024px) {
      display: unset;
    }
  }

  .MuiPaper-elevation4 {
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.5);
  }
}

.SimpleHeaderDropdown {
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    margin-top: 14px;
  }

  .MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0;
  }
  .MuiMenuItem-root {
    padding: 10px 20px;
  }
  .MuiListItem-button {
    background: var(--white);
  }
  .MuiListItem-button:hover {
    color: var(--primary_brand_color);
  }
  .MuiPaper-rounded {
    border-radius: 0;
  }

  .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.menuitemHeader {
    font-family: var(--font_family_Semibold) !important;
    display: flex;
    justify-content: center;
    font-size: 14px;
    width: 135px;
  }
}

.modalMainSimpleHeader {
  z-index: 1111 !important;
  .closeIcon {
    height: 25px;
    width: 25px;
    margin: 25px 25px 10px 0;
    cursor: pointer;
    margin-left: auto;
  }

  .bottomContent {
    margin: auto 0 30px 40px;
    .innerLast {
      svg {
        height: 40px;
        width: 40px;
      }
      .name {
        font-family: var(--font_family_Semibold);
        font-size: 16px;
        font-weight: var(--font_weight_2);
        color: var(--text_color);
        margin-top: 13px;
      }
      .otherHead {
        margin: 30px 0 40px 0;
        cursor: pointer;
      }
      .subtext {
        margin: 30px 0;
        font-family: var(--font_family_Semibold);
        font-size: 14px;
        color: var(--text_color);
        cursor: pointer;
      }
    }
  }
  .modal.show .modal-dialog {
    display: flex !important;
    justify-content: center !important;
  }
  .modal-dialog {
    margin: 0 !important;
    height: 100% !important;
  }
  .modal-dialog-centered {
    justify-content: center;
  }
  .modal-lg,
  .modal-xl {
    max-width: 100% !important;
  }
  .modal-content {
    width: 100% !important;
    height: 100%;
  }
}
