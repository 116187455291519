@import '../../global/globalStyle.scss';
@import '../../global/globalColorcss.scss';
.BringYourPhoneMain {
    @media (max-width: 1024px) {
        margin-top: 70px !important;
    }
    .BringYourPhoneCarosel {
        .OverlayPlan {
            padding: 0 20px;
            .contentCarousel {
                width: 470px;
                position: absolute;
                left: calc(100% - 53% - 207px);
                top: 13%;
                @media (min-width: 821px) and (max-width: 1024px) {
                    width: 48% !important;
                    top: 8% !important;
                    left: calc(100% - 55% - 207px) !important;
                    top: 15%;
                }
                @media (min-width: 601px) and (max-width: 768px) {
                    top: 8% !important;
                    left: calc(100% - 54% - 207px) !important;
                }
                @media (max-width: 600px) {
                    width: 100%;
                    left: calc(100% - 48% - 207px);
                    top: 13% !important;
                }
                .skeletonRadius {
                    border-radius: 25px !important;
                }
                .Goodbye-mobile-store {
                    font-family: var(--font_family_Demibold);
                    color: var(--text_color);
                    font-size: 36px;
                    font-weight: var(--font_weight_2);
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.11;
                    letter-spacing: normal;
                    text-align: center;
                    @media (max-width: 600px) {
                        font-size: 22px;
                        padding: 0px 30px;
                    }
                }
            }
            .carouseImage {
                position: relative;

                .web {
                    @media (max-width: 1024px) {
                        display: none;
                    }
                    @media (max-width: 768px) {
                        display: none;
                    }
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
                .iPadPro {
                    display: none;
                    @media (max-width: 1024px) {
                        display: unset;
                    }
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
                .iPad {
                    display: none;
                    @media (max-width: 768px) {
                        display: unset;
                    }
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
                .mobile {
                    display: none;
                    @media (max-width: 600px) {
                        display: unset;
                        width: 100%;
                    }
                }
            }
            @media (max-width: 1024px) {
                padding: 0;
            }
        }
    }
    .mediaQueriesBringYourPhone {
        .BringYourPhoneContent {
            .compatibilityPlan1 {
                .limit-div {
                    font-family: var(--font_family_Medium);
                    font-weight: var(--font_weight_0);
                    display: flex;
                    font-size: 12px;
                    justify-content: flex-end;
                    margin: 10px 0;
                }
                .checkCoveragePlanLoader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .check-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin: 0 auto;
                    button {
                        background-color: var(--primary_color);
                        color: var(--white);
                    }
                    .loaderCheck {
                        display: flex;
                        display: -webkit-box;
                        .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
                            height: 30px !important;
                            width: 30px !important;
                        }
                        // svg {
                        //   color: #48688f;
                        // }
                    }
                }
                .input-div {
                    margin: 20px 0;
                }
                .phone-trouble-div {
                    display: flex;
                    justify-content: space-between;
                }
                .above-email-text {
                    font-size: 14px;
                    font-family: var(--font_family_Medium);
                    font-weight: var(--font_weight_0);
                }
                .popover-body,
                .popover {
                    max-width: fit-content !important;
                    width: fit-content !important;
                    min-width: 300px !important;
                }

                .having-trouble-div {
                    margin-left: auto;
                    color: var(--primary_color);
                    font-size: 14px;
                    cursor: pointer;
                }
                .compatibilityPlanTitle {
                    font-family: var(--font_family_Demibold);
                    font-size: 22px;
                    font-weight: var(--font_weight_4);
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.77;
                    letter-spacing: normal;
                    text-align: center;
                    color: var(--text_color_1);
                    margin-top: 50px;
                    @media (max-width: 600px) {
                        margin-bottom: 25px;
                    }
                }
                .compatibilityPlanSubTitle {
                    font-family: var(--font_family_Semibold);
                    font-size: 15px;
                    font-weight: var(--font_weight_1);
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    letter-spacing: normal;
                    text-align: center;
                    color: var(--subtext-color);
                }

                .compatibilityPlanInput {
                    width: 600px;
                    margin: 30px auto 30px auto;
                    .popover-body,
                    .popover {
                        @media (max-width: 600px) {
                            width: 340px !important;
                            min-width: 340px !important;
                        }
                        @media (max-width: 320px) {
                            width: 280px !important;
                            min-width: 280px !important;
                        }
                    }
                }

                .SuccessCompatibility {
                    width: 700px;
                    margin: 30px auto 30px auto;
                    .BackButton {
                        color: var(--text_color);
                        font-size: 1rem;
                        padding-bottom: 20px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .arrow_icon {
                            margin-right: 10px;
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                            margin-top: -2px;
                        }
                        div {
                            margin-top: 1px;
                        }
                    }
                    .individualRow {
                        display: flex;
                        justify-content: space-between;
                        padding: 20px 0;
                        border-bottom: solid 1px #e3e3e3;
                        .textLeft {
                            color: var(--grey_shade);
                            font-size: 16px;
                            font-family: var(--font_family_Semibold);
                        }
                        .textRight {
                            color: var(--text_color);
                            font-size: 16px;
                            font-family: var(--font_family_Semibold);
                            @media (max-width: 600px) {
                                margin-top: 10px;
                            }
                        }
                        .noEsimSupport {
                            color: var(--danger);
                        }
                        @media (max-width: 600px) {
                            justify-content: flex-start;
                            flex-direction: column;
                        }
                    }
                    .CardDiv {
                        width: 100%;
                        margin-top: 1.5rem;
                        padding: 1rem 15px;
                        border: solid 1px #e3e3e3;
                        border-radius: 5px;
                        .innerDiv {
                            display: flex;
                            justify-content: space-between;

                            .LeftDiv {
                                display: flex;

                                .image {
                                    width: 30px;
                                    margin: 5px 0;
                                    @media (max-width: 600px) {
                                        margin: 15px 0;
                                    }
                                }
                                .text1 {
                                    color: var(--text_color);
                                    font-size: 15px;
                                    margin-left: 20px;
                                }

                                .text2 {
                                    color: var(--subtect-color_1);
                                    font-size: 14px;
                                    margin-left: 20px;
                                }
                            }
                            .RightDiv {
                                .innerRightDiv {
                                    display: flex;

                                    .code {
                                        border: 2px dashed var(--primary_color);
                                        text-align: center;
                                        padding: 5px;
                                        float: left;
                                        color: var(--primary_color);
                                        width: 100px;
                                        cursor: pointer;
                                    }
                                    .image {
                                        width: 30px;
                                        margin: 5px 0 5px 20px;
                                        cursor: pointer;
                                    }
                                    .shareContainer {
                                        width: 350px;
                                        .shareHeader {
                                            display: flex;

                                            .closeIcon {
                                                height: 15px;
                                                width: 15px;
                                                cursor: pointer;
                                                color: var(--black);
                                                opacity: 0.5;
                                            }
                                            .shareHeaderText {
                                                font-family: var(--font_family);
                                                font-weight: var(--font_weight_4);
                                                font-size: 16px;
                                                text-align: center;
                                                margin: 0 auto;
                                            }
                                        }
                                        .icons {
                                            display: flex;
                                            justify-content: space-around;
                                            .individualIcon {
                                                margin-top: 10px;
                                                text-align: center;
                                                .icon {
                                                    width: 50px;
                                                }
                                                .text {
                                                    margin-top: 10px;
                                                    font-size: 14px;
                                                    font-family: var(--font_family_Medium);
                                                    color: var(--text_color_4);
                                                }
                                                .imeiMailLink {
                                                    text-decoration: none;
                                                }
                                            }
                                        }
                                        @media (max-width: 600px) {
                                            width: 100%;
                                        }
                                    }
                                }
                                @media (max-width: 600px) {
                                    margin-top: 1rem;
                                    margin-left: 3.3rem;
                                }
                            }
                            @media (max-width: 600px) {
                                flex-direction: column;
                                justify-content: flex-start;
                            }
                        }
                    }
                    .viewPlanBtn {
                        width: 300px;
                        margin: 20px auto;
                        @media (max-width: 1024px) {
                            width: 100%;
                            margin: 20px 0;
                        }
                    }
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
                .compatibilityPlanBtn {
                    width: 290px;
                    margin: 0 auto;
                }
                .compatibilityPlanLoader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                @media (max-width: 600px) {
                    .compatibilityPlanInput {
                        width: 100%;
                        margin: 0 20px 30px 0px;
                    }
                    .compatibilityPlanBtn {
                        width: 100%;
                    }
                }
            }
            .AccordionComponent {
                width: 600px;
                margin: 0 auto 20px auto;
                .HeadingAccordian {
                    padding: 30px 10px 20px 10px;
                    font-family: var(--font_family);
                    font-size: 16px;
                    font-weight: var(--font_weight_4);
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: var(--text_color);
                }
                .accordion-item {
                    // height: 40px;
                    border: none;
                    .accordion-button {
                        font-family: var(--font_family_Semibold);
                        font-size: 14px;
                        padding: 10px;
                        font-weight: var(--font_weight_2);
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: var(--text_color);
                        cursor: pointer;
                    }
                    .accordion-button:focus {
                        border-color: none;
                        box-shadow: none;
                    }
                    .accordion-body {
                        // font-family: SharpSans-Book;
                        font-family: var(--font_family_Medium);
                        font-size: 14px;
                        font-weight: var(--font_weight_0);
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.75;
                        letter-spacing: normal;
                        color: var(--accordion-body_color);
                        padding: 1rem 0.7rem;
                        text-align: left;
                    }
                    .accordion-button::after {
                        background-image: url('../../assets/images/commonImages/arrow_drop_down_black_24dp.svg');
                    }
                    .accordion-button:not(.collapsed) {
                        background-color: var(--white);
                        border: none;
                        box-shadow: none;
                    }
                }
                @media (max-width: 600px) {
                    width: 100%;
                }
            }
            .HaveTroubleToolTip {
                width: 350px;
                .HaveTroubleHeader {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .closeIcon {
                        height: 15px;
                        width: 15px;
                        cursor: pointer;
                        color: var(--black);
                        opacity: 0.5;
                    }
                    .HaveTroubleHeaderText {
                        font-family: var(--font_family);
                        font-weight: var(--font_weight_4);
                        font-size: 16px;
                        text-align: center;
                        margin: 0 auto;
                    }
                }
                .haveTroubleDetaildiv {
                    display: flex;
                    .detailImgDiv {
                        margin-right: 15px;
                        .detailImg {
                            width: 23px;
                            height: 28px;
                        }
                    }
                    .detailContent {
                        .detailHead {
                            color: var(--text_color_5);
                            font-family: var(--font_family_Semibold);
                            font-size: 14px;
                        }
                        .detailSubText {
                            font-family: var(--font_family_Medium);
                            font-size: 14px;

                            color: var(--text_color);
                        }
                        .addMarginBottom {
                            margin-bottom: 15px;
                        }
                    }
                }
                @media (max-width: 380px) {
                    width: 100%;
                }
            }
        }

        @media (max-width: 1536px) {
            margin: 0px 10px 0px 10px;
        }
        @media (max-width: 1440px) {
            margin: 0px 30px 0px 30px;
        }

        @media (max-width: 1024px) {
            margin: 0px 15px 0px 15px;
        }
    }
    .BringYouPhoneTitle {
        font-family: var(--font_family);
        font-size: 26px;
        font-weight: var(--font_weight_4);
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: var(--text_color);
        text-align: center;
    }
    .BringYouPhoneLine {
        width: 80px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--primary_brand_color);
        margin: 10px auto 20px auto;
    }
}
.compatibilityPlanBring {
    position: absolute;
    top: calc(100% - 50% - 250px);
    left: calc(100% - 50% - 270px);
    background: var(--white);
    padding: 30px;
    .modalImage {
        width: 60px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    .BummerDiv {
        width: 540px;

        .BummerContent {
            font-family: var(--font_family_Semibold);
            font-size: 14px;
            line-height: 1.6;
            text-align: center;
            color: var(--text_color);
            padding: 20px 0;
        }
        .shopPhoneButton {
            padding: 0px 0 20px 0;
            // width: 45%;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
            @media (max-width: 600px) {
                width: 100%;
                flex-direction: column;
                padding: 0;
            }
        }
        .shopPhoneButton > button {
            width: 45%;

            @media (max-width: 600px) {
                width: 100%;
                margin: 0 0 20px 0 !important;
            }
        }

        .tryAgain {
            font-size: 14px;
            font-family: var(--font_family_Semibold);
            color: var(--primary_color);
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    .BummerDivSuccess {
        width: 540px;

        .BummerContent {
            font-family: var(--font_family_Semibold);
            font-size: 16px;
            line-height: 1.6;
            text-align: center;
            color: var(--text_color);
            padding: 20px 0;
        }
        .shopPhoneButton {
            padding: 0px 0 20px 0;
            width: 45%;
            margin: 0 auto;
            @media (max-width: 600px) {
                width: 100%;
            }
        }
        .tryAgain {
            font-size: 14px;
            font-family: var(--font_family_Semibold);
            color: var(--primary_color);
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }

    @media (max-width: 600px) {
        padding: 25px;
        left: 0;
        top: calc(100% - 50% - 280px);
        margin: 10px;
        // width: calc(100% - 70px);
    }
}
