.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.modal {
  z-index: 1500 !important;
}

/* .modal-backdrop {
  z-index: 1400 !important;
} */
.modal {
  z-index: 1500 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
html,
body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

html,
body,
p,
a,
span,
h1,
div,
section,
ul,
li,
button,
h2,
h3,
h4,
h5,
strong,
input,
option,
h6 {
  font-family: var(--font_family_Medium);
}


/* .reach-notify{
  background-color:var( --primary_background) ;
  color:var( --white);
  width: 100%;
  padding: 10px;
}

.link-notify{
  color:var( --white);
} */

