@import '../../global/globalStyle.scss';
@import '../../global/globalColorcss.scss';

.homePlans {
    margin: 30px auto;
    .homePlanMainBox {
        display: flex;
        justify-content: center;
        @media (max-width: 600px) {
            display: block;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            display: block;
        }
    }
    .homePlanBox {
        box-shadow: 5px 5px 5px rgba(170, 170, 170, 0.1);
        border: 0.2px solid rgba(170, 170, 170, 0.1);
        width: 500px;
        margin: 0 2%;
        border-radius: 4px;
        border: 0.5px solid #ccc;
        @media (max-width: 600px) {
            width: 100%;
            margin: 0 0 20px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            width: 80%;
            margin: 0 auto 20px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
            width: 45%;
            margin: 0 auto 20px;
        }
        // @media (max-width: 1024px) {
        //     width: 85%;
        //     margin: 0 auto 20px;
        // }
    }
    .homePlanUnlimited {
        // display: flex;
        // justify-content: space-around;
        // align-items: center;
        display: block;
        @media (max-width: 768px) {
            display: block;
            width: 100%;
        }
    }
    .plansPadding {
        padding: 0 30px 30px;
    }

    .planLabel {
        border-radius: 2px;
        background-color: var(--lavender);
        padding: 3px 8px;
        width: 30%;
        float: right;
        span {
            font-family: var(--font_family_Semibold);
            font-size: 12px;
            color: var(--white);
            margin-left: 4px;
        }
        @media (max-width: 600px) {
            width: 40%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            width: 25%;
        }
    }
    .clearFloat {
        clear: both;
    }
    .planHead {
        font-family: var(--font_family_Semibold);
        font-size: 24px;
        color: var(--text_color);
        @media (max-width: 600px) {
            font-size: 22px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            font-size: 22px;
        }
    }
    .planText {
        font-family: var(--font_family_Semibold);
        font-size: 18px;
        color: var(--text_color);
        margin: 10px 0;
        @media (max-width: 600px) {
            font-size: 16px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            font-size: 16px;
        }
    }
    .plan-border-bottom {
        width: 75px;
        height: 5px;
        background-color: var(--primary_brand_color);
        margin-bottom: 20px;
        border-radius: 25px;
        margin: 0;
    }
    .dataItems {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .dataBox {
        border-radius: 2px;
        border: 1px solid #cccccc;
        padding: 5px;
        margin: 5px;
        width: 20%;
        text-align: center;
        cursor: pointer;
        font-size: 22px;
        font-family: var(--font_family_Semibold);
        @media (max-width: 600px) {
            width: 27%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            width: 20%;
        }
    }
    .planPerMonthCost {
        color: var(--text_color);
        font-family: var(--font_family_Medium);
        font-size: 18px;
        span:nth-child(1) {
            font-family: var(--font_family);
            font-size: 40px;
            color: var(--primary_brand_color);
        }
        span:nth-child(2) {
            font-family: var(--font_family_Semibold);
            font-size: 18px;
            color: var(--primary_brand_color);
        }
        span:nth-child(3) {
            font-family: var(--font_family_Semibold);
            font-size: 18px;
            color: var(--grey);
        }
        @media (max-width: 600px) {
            font-size: 14px;
            span:nth-child(1) {
                font-size: 35px;
            }
            span:nth-child(2) {
                font-size: 14px;
            }
            span:nth-child(3) {
                font-family: var(--font_family_Semibold);
                font-size: 14px;
                color: var(--grey);
            }
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            font-size: 16px;
            span:nth-child(1) {
                font-size: 40px;
            }
            span:nth-child(2) {
                font-size: 16px;
            }
        }
    }
    .planPerLineCost {
        font-family: var(--font_family_Semibold);
        font-size: 22px;
        color: var(--text_color);
        span {
            font-family: var(--font_family);
        }
        @media (max-width: 600px) {
            font-size: 20px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            font-size: 20px;
        }
    }
    .unlimitedEmptyBox {
        padding: 15px;
    }
    .unlimitedPlanText {
        color: var(--text_color);
        font-family: var(--font_family_Semibold);
        font-size: 18px;
        margin-top: 25px;
        margin-bottom: 20px;
        @media (max-width: 600px) {
            margin-left: 0px;
            margin-top: 15px;
            font-size: 16px;
            margin-bottom: 10px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            margin-left: 0px;
            margin-top: 15px;
            font-size: 16px;
            margin-bottom: 10px;
        }
        @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
            margin-bottom: 15px;
        }
    }
    .planSelectButtonBlk {
        margin-top: 30px;
    }
    .activeBox {
        border: 1px solid #3a8580;
    }
    .activePlan {
        background: var(--primary_brand_color);
        color: var(--white);
    }
}
.planOfferText {
    color: var(--primary_brand_color);
    font-family: var(--font_family_Medium);
    font-size: 20px;
    margin: 20px 0 10px;
    text-align: left;

    .planOfferTextOne {
        color: var(--primary_brand_color);
        font-family: var(--font_family_Medium);
        font-size: 20px;
        @media (max-width: 600px) {
            font-size: 16px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            font-size: 16px;
        }
        @media (max-width: 1024px) {
        }
    }
    .planOfferTextTwo {
        display: flex;
        align-items: center;
        justify-content: start;
        .lineBoxImage {
            margin-bottom: 40px;
        }
        p {
            color: var(--primary_brand_color);
            font-family: var(--font_family_Medium);
            font-size: 20px;
            margin-bottom: 0;
            margin-left: 5px;
        }
        @media (max-width: 600px) {
            font-size: 16px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
            font-size: 16px;
        }
    }
}
.cardsDiv{
    @media (max-width: 375px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media (max-width:768px) {
        flex-wrap: wrap;
    }
    @media (max-width:1024px) {
        flex-wrap: wrap;
    }
}
