.spotlightDiv {
  background-color: var(--lite_background);
  padding: 95px 0px;
  @media (max-width: 1024px) {
    // margin-top: 70px;
    height: 571px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 600px) {
    // margin-top: 15px;
    margin-bottom: 15px;
    height: 100%;
    padding-top: 50px;
  }
  @media (min-width: 601px) and (max-width: 780px) {
    height: 685px;
  }
}

.homepageMain {
  @media (max-width: 1024px) {
    margin-top: 70px !important;
  }
  margin: unset;
  @media (min-width: 1024px) {
    margin: auto 25px;
  }
  .features_highlight_btn {
    text-align: center;
    background-color: var(--primary_background);
    width: 485px;
    left: calc(100% - 49% - 257px);
    margin: -45px 0px 0px 0px;
    padding: 20px 0;
    color: var(--white);
    position: absolute;
    @media (max-width: 600px) {
      width: 100%;
      left: 0px;
      position: unset;
    }
    @media (min-width: 601px) and (max-width: 780px) {
      left: calc(100% - 47% - 257px) !important;
    }

    // @media (max-width: 1024px) {
    //   left: calc(100% - 40% - 257px);

    // }
    h3 {
      font-weight: var(--font_weight_2);
      font-size: 30px;
      font-family: var(--font_family_Demibold);
      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
  }

  .TrustPilotMainDiv {
    padding-top: 91px !important;
    @media (max-width: 600px) {
      padding-top: 30px !important;
      height: 430px;
      margin-bottom: 0px;
    }
    @media (max-width: 1024px) {
      padding-top: 30px !important;
      height: 419px;
    }
    height: 562px;
    .TrustPilot {
      @media (max-width: 600px) {
        margin: 0px 0px 0px 0px;
      }
    }
    @media (max-width: 600px) {
      margin: 25px 0 50px;
      padding: 20px 0;
    }
    background: var(--primary_background);
    padding-top: 50px;
    .HomePageTrustTitle {
      font-family: var(--font_family_Demibold);
      font-size: 30px;
      font-weight: var(--font_weight_2);
      color: var(--white);
      text-align: center;
      @media (max-width: 600px) {
        font-size: 22px;
        padding: 0 40px;
      }
      @media (max-width: 1024px) {
        font-size: 22px;
      }
    }
    .HomePageTrustSubTitle {
      font-family: var(--font_family_Medium);
      font-size: 25px;
      font-weight: var(--font_weight_1);
      color: var(--white);
      margin-bottom: 50px !important;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 20px;
        padding: 0 10px;
        display: none;
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .HomePageTrustLine {
      width: 80px;
      height: 5px;
      border-radius: 2.5px;
      background-color: var(--dusty-orange);
      margin: 20px auto 25px auto;
    }
  }

  .mediaQueriesHome {
    .homePageContent {
      .bestCellPhonePlan {
        margin-top: 70px;
        font-weight: var(--font_weight_2);
        .HomePageTitle {
          font-size: 30px;
          color: var(--text_color);
          text-align: center;
          @media (max-width: 600px) {
            font-size: 22px;
            padding: 0 30px;
          }
          @media (max-width: 1024px) {
            font-size: 30px;
          }
        }

        .LandingTitle {
          font-family: var(--font_family);
          font-size: 26px;
          font-weight: var(--font_weight_4);
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: var(--text_color);
          text-align: center;
        }

        .cardsDiv {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 25px;
          font-family: var(--font_family_Medium);
          @media (max-width: 600px) {
            margin-bottom: 10px;
          }
          // @media (max-width: 1024px) {
          //   width: 80%;
          //   margin-left: 10%;
          // }
        }
        @media (max-width: 600px) {
          margin-top: 50px;
        }
      }
    }
  }
}

.mt-3 {
  font-weight: var(--font_weight_2);
}

.broadband-card {
  text-align: left;
  .planSelectButtonBlk {
    width: 75%;
    margin: 0 auto;
  }
  .colour-font {
    color: var(--primary_brand_color);
  }
  .fs-40 {
    font-size: 40px;
  }
  .fs-24 {
    font-size: 24px;
  }
  .fs-10 {
    font-size: 10px;
  }
  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 16px;
  }
  .fs-18 {
    font-size: 18px;
  }
  .pl-1 {
    padding-left: 0.25rem !important;
  }
  .pl-2 {
    padding-left: 0.5rem !important;
  }
  .pl-3 {
    padding-left: 0.75rem !important;
  }
  .pl-4 {
    padding-left: 1rem !important;
  }
  .pr-1 {
    padding-right: 0.25rem !important;
  }
  .pr-2 {
    padding-right: 0.5rem !important;
  }
  .pr-3 {
    padding-right: 0.75rem !important;
  }
  .pr-4 {
    padding-right: 1rem !important;
  }
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .ml-1 {
    margin-left: 0.25rem !important;
  }
  .ml-2 {
    margin-left: 0.5rem !important;
  }
  .ml-3 {
    margin-left: 0.75rem !important;
  }
  .ml-4 {
    margin-left: 1rem !important;
  }
  .mr-1 {
    margin-right: 0.25rem !important;
  }
  .mr-2 {
    margin-right: 0.5rem !important;
  }
  .mr-3 {
    margin-right: 0.75rem !important;
  }
  .mr-4 {
    margin-right: 1rem !important;
  }
  .text-right {
    text-align: right !important;
  }

  .font-family-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
  }
  .font-family-semibold {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .font-family-medium {
    font-family: "Roboto", sans-serif;
  }
  .border-bottom-black-1 {
    border: 0.5px solid #313131;
    border-color: #313131 !important;
    opacity: 1;
  }
}
