.discoverSection {
  font-family: var(--font_family_Medium);

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  .top {
    @media (min-width: 1025px) {
      padding-top: 60px !important;
    }
  }
  .discover {
    margin-top: 127px;
    @media (max-width: 1024px) {
      margin-top: 60px !important;
    }
    @media (max-width: 600px) {
      margin-top: 50px !important;
    }
  }
  .discoverTitle {
    font-weight: var(--font_weight_2);
    font-size: 30px;
    font-family: var(--font_family_Demibold);
    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
      font-size: 22px;
    }
  }

  .topsubTitle {
    font-family: var(--font_family_Medium);
    font-size: 18px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .step {
    position: relative;
    min-height: 1.5em;
    color: var(--text_color);
    .stepATitle {
      height: 24px;
      font-family: var(--font_family_Medium);
      font-size: 16px;
      font-weight: var(--font_weight_2);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: var(--text_color);

      .stepNumbering {
        width: 19px;
        margin: 1px 8px 6px 0;
        font-family: var(--font_family_Medium);
        font-size: 30px;
        font-weight: var(--font_weight_2);
        font-stretch: normal;
        font-style: normal;
        line-height: 0.8;
        letter-spacing: normal;
        color: var(--primary_color);
        height: 24px;
      }
    }
    .stepADesc {
      // width: 543px;
      height: 24px;
      margin: 6px 0 0;
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      font-weight: var(--font_weight_2);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: var(--text_color);
    }
    .stepTitle {
      font-size: 18px;
      font-weight: var(--font_weight_2);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }
    .stepDesc {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: var(--font_weight_1);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
  .step + .step {
    margin-top: 1.5em;
  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > .Contain {
    margin-left: 0em;
    padding-left: 0em;
  }
  .step > .notFirst {
    margin-left: 4.5em;
    padding-left: 1em;
  }
  //   .step.step-active {
  //     color: #4285f4
  //   }
  //   .step.step-active .circle {
  //     background-color: #4285f4;
  //   }

  /* Circle */
  .circle {
    // background: gray;
    position: relative;
    width: 1.5em;
    height: 2.1em;
    // line-height: 1.5em;
    // border-radius: 100%;
    // color: #fff;
    text-align: center;
    // box-shadow: 0 0 0 3px #fff;
  }

  /* Vertical Line */
  .circle:after {
    content: " ";
    position: absolute;
    display: block;
    top: 5px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: -1;
  }
  .step:last-child .circle:after {
    display: none;
  }
  .discoverImage {
    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
    }
    .web {
      padding-left: 150px;
      height: 500px;
      @media (max-width: 1024px) {
        display: none;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    .iPadPro {
      display: none;
      @media (max-width: 1024px) {
        display: unset;
        margin-top: 50px;
        margin-left: 250px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .iPad {
      display: none;
      @media (max-width: 768px) {
        display: unset;
        margin-top: 50px;
        margin-left: 100px;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    .mobile {
      display: none;
      @media (max-width: 600px) {
        display: unset;
        width: 283px;
        height: 300px;
        margin-top: 50px;
      }
    }
  }
  .checkLink {
    color: var(--primary_color);
    cursor: pointer;
    font-size: 14px;
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
  }
}
.BrandLine {
  width: 85px;
  height: 4px;
  border-radius: 2.5px;
  background-color: var(--dusty-orange);
  margin: 20px auto 25px 0px;
}

.HomePageLine {
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}
