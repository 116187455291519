@import "../../global/globalStyle.scss";
@import "../../global/globalColorcss.scss";

.homeSpotlightCardsBlk {
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
    width: 100%;
  }
  @media (max-width: 1024px) {
    // width: 75%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
    flex-wrap: wrap;
  }
  .feature-second-sec {
    margin-top: 20px;
    @media (max-width: 600px) {
      margin-top: 0;
    }
  }

  .itemBox {
    margin: 5px;
    // width: 24%;
    // @media (max-width: 1980px) {
    //   width: 21%;
    // }
    @media (max-width: 600px) {
      width: 100%;
      margin: 15px 0;
    }
    @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
      // width: 45%;
      margin: 12px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
      width: 30%;
      margin: 10px;
    }
    h5 {
      font-family: var(--font_family_Medium);
      font-size: 24px;
      color: var(--text_color);
      margin-bottom: 9px;
      padding-bottom: 0;
      @media (max-width: 600px) {
        font-weight: var(--font_weight_2);
      }
    }
    p {
      font-family: var(--font_family_Medium);
      font-size: 18px;
      color: var(--text_color);
      margin-bottom: 0px;
      @media (max-width: 600px) {
        padding-left: 48px;
        padding-right: 70px;
      }
    }
    .itemImage {
      margin-bottom: 30px;
    }
  }
}

.cards-head {
  padding: 13px !important;
}
