@import '../../global/globalStyle.scss';
@import '../../global/globalColorcss.scss';
.TabComponent {
    max-height: 45px;
    margin: 65px auto 0 auto;

    // height: 45px;
    padding: 7px 35px;
    border-radius: 4px;
    border: solid 1px #979797;
    background-color: #ffffff;
    width: fit-content;
    display: flex;
    .TabDiv {
        margin: 0 17.5px;
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;
        @media(max-width:320px){
            margin: 0 12.5px;

        }
    }
    .tabDetail {
        text-align: center;
        padding: 2px 0;
        color: var(--text_color);
    }
    .tabDetailActive {
        text-align: center;
        padding: 2px 0px;
        width: 60px;
        border-radius: 4px;
        color: var(--white);
        background-color: var(--primary_brand_color);
    }
    // .tabImg::before {
    //     opacity: 0;
    // }
    .tabImg {
        height: 45px;
        position: absolute;
        top: -52px;
        // opacity: 1;
        // transition: opacity 3s ease-in-out;
    }
    @media (max-width: 1024px) {
        padding: 7px 10px;
        margin-bottom: 50px;
    }
    @media (max-width: 600px) {
        padding: 7px 10px;
    }
}
