@import "../../global/globalStyle.scss";
@import "../../global/globalColorcss.scss";
.headerMain {
  // flex-grow: 1;
  .fontStyleHeader {
    font-family: var(--font_family_Semibold);
  }

  .MuiAppBar-colorPrimary {
    background-color: var(--white);
  }

  .headerContent {
    height: 71px;
    .headerLogo {
      padding: 0px 0px 0px 40px;
      width: 178px;
      height: 52px;
      cursor: pointer;
    }
    .headerInnerDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1680px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      .clickedHeaderTab {
        .MuiButtonBase-root {
          color: var(--primary_brand_color);
        }
      }

      .MuiButtonBase-root {
        color: var(--text_color);
        font-family: var(--font_family_Semibold);
        font-size: 14px;
        font-weight: var(--font_weight_2);
      }
      .MuiButtonBase-root:active,
      .MuiButtonBase-root:hover,
      .MuiButtonBase-root:focus {
        color: var(--primary_brand_color);
        background: var(--white);
      }
      .MuiButton-root:active,
      .MuiButton-root:hover {
        background: var(--white);
      }

      .arrayDivHeader {
        display: flex;
        justify-content: space-around;
        // align-items: center;
        height: 87%;
        .homeinternetlink {
          color: var(--primary_brand_color);
          text-decoration: none;
        }
        .buttonMiddleHeader {
          font-size: 16px;
          margin: 0 30px;
          padding-top: 15px;
          text-align: center;

          .homeinternetlink {
            color: var(--primary_brand_color);
            text-decoration: none;
          }

          .ArrowUpKey {
            fill: var(--primary_brand_color);
          }

          // @media (max-width: 1280px) {
          //     margin-right: 10px;
          // }
        }
        .buttonMiddleHeaderPost {
          margin-right: 60px;
          padding-top: 15px;

          .ArrowUpKey {
            fill: var(--primary_brand_color);
          }

          // @media (max-width: 1280px) {
          //     margin-right: 10px;
          // }
        }

        .activeRouteHeader {
          border-bottom: 4px solid var(--primary_brand_color);
          padding-bottom: 47px;
        }
        .buttonMiddleHeader1 {
          margin-right: 30px;

          .paddingAdded {
            padding-top: 20px;
          }
          .lastDiv1 {
            padding-top: 10px;

            .innerLast {
              display: flex;
              align-items: center;
              .MuiSvgIcon-root {
                fill: var(--primary_brand_color);
                height: 30px;
                width: 30px;
                margin-right: 5px;
              }
              .name {
                font-family: var(--font_family_Semibold);
                font-size: 14px;
                font-weight: var(--font_weight_2);
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: var(--text_color_2);
              }
            }
          }
        }
      }
      .otherButtons {
        .MuiButton-label {
          color: var(--grey_shade);
        }
        .MuiButton-label:active,
        .MuiButton-label:hover {
          color: var(--primary_brand_color);
          background: var(--white);
        }
        .MuiButton-root:active,
        .MuiButton-root:hover {
          background: var(--white);
        }
      }
      @media (max-width: 1920px) {
        max-width: 1920px;
      }
      @media (max-width: 1536px) {
        max-width: 1536px;
      }
      @media (max-width: 1440px) {
        max-width: 1440px;
      }
      @media (max-width: 1366px) {
        max-width: 1366px;
      }
      @media (max-width: 1280px) {
        max-width: 1280px;
      }
      @media (max-width: 1024px) {
        max-width: 1024px;
      }

      @media (max-width: 1024px) {
        display: none;
        height: unset;
      }
    }

    @media (max-width: 1920px) {
      max-width: 1920px;
    }
    @media (max-width: 1536px) {
      max-width: 1536px;
    }
    @media (max-width: 1440px) {
      max-width: 1440px;
    }
    @media (max-width: 1366px) {
      max-width: 1366px;
    }
    @media (max-width: 1280px) {
      max-width: 1280px;
    }
    @media (max-width: 1024px) {
      max-width: 1024px;
    }

    @media (max-width: 1024px) {
      display: none;
      height: unset;
    }
  }
  .headerContentMobile {
    display: none;
    .headerLogoMobile {
      padding: 0 0 0 15px;
      width: 120px;
      height: 35px;
      cursor: pointer;
      @media (max-width: 600px) {
        padding: 0 0 0 10px;
      }
    }
    .headerContentMobileinner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 71px;
    }
    @media (max-width: 1024px) {
      display: unset;
    }
  }

  .MuiPaper-elevation4 {
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.5);
  }

  // @media (max-height: 900px) and (max-width: 600px) {
  //     height: 25px;
  // }
  // @media (max-height: 750px) and (max-width: 600px) {
  //     height: 30px;
  // }
  // @media (max-height: 700px) and (max-width: 600px) {
  //     height: 20px;
  // }
  // @media (max-height: 650px) and (max-width: 600px) {
  //     height: 20px;
  // }
  // @media (max-height: 600px) and (max-width: 600px) {
  //     height: 20px;
  // }
}
.HeaderDropdown {
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    margin-top: 16px;
  }

  .MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0;
  }
  .MuiMenuItem-root {
    padding: 10px 20px;
  }
  .MuiListItem-button {
    background: var(--white);
  }
  .MuiListItem-button:hover {
    color: var(--white);
    background: var(--primary_brand_color);
  }
  .MuiPaper-rounded {
    border-radius: 0;
  }

  .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.menuitemHeader {
    font-family: var(--font_family_Semibold) !important;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
}
.modalMainHeader {
  z-index: 1111 !important;
  .closeIcon {
    height: 25px;
    width: 25px;
    margin: 25px 25px 10px 0;
    cursor: pointer;
    margin-left: auto;
  }

  .bottomContent {
    padding: 4%;
    margin: auto 0 0 0;
    .activeRouteHeader {
      border-bottom: 4px solid var(--primary_brand_color) !important;
      color: var(--primary_brand_color) !important;
      font-family: var(--font_family) !important;
      padding-bottom: 0 !important;
      margin-bottom: 20px !important;
    }
    .menuItems {
      font-size: 16px;
      font-family: var(--font_family_Semibold);
      margin-bottom: 6px;
      padding: 10px 0;
      color: var(--text_color);
      width: fit-content;
      .homeinternetlink {
        color: var(--primary_brand_color);
        text-decoration: none;
      }
    }
    .dropdownL {
      svg {
        margin-top: -5px;
        margin-left: 5px;
      }
    }
    .subMenuItems {
      font-size: 14px;
      font-family: var(--font_family_Semibold);
      color: var(--text_color);
      padding: 10px 0 20px 0;
      width: fit-content;
    }
    .greyColor {
      color: var(--grey_shade) !important;
    }
  }
  .modal.show .modal-dialog {
    display: flex !important;
    justify-content: center !important;
  }
  .modal-dialog {
    margin: 0 !important;
    height: 100% !important;
  }
  .modal-dialog-centered {
    justify-content: center;
  }
  .modal-lg,
  .modal-xl {
    max-width: 100% !important;
  }
  .modal-content {
    width: 100% !important;
    height: 100%;
  }
}
.logoMobile {
  width: 120px;
}
