.compatibilityPlanBring1 {
  position: absolute;
  top: calc(100% - 50% - 270px);
  left: calc(100% - 50% - 270px);
  background: var(--white);
  padding: 30px;
  width: 540px;
  @media (max-width: 600px) {
    position: absolute;
    top: calc(100% - 50% - 200px);
    left: calc(100% - 50% - 200px);
    background: var(--white);
    padding: 30px;
    width: 100%;
  }
  .modalInner {
    height: 500px;
    overflow: scroll;
    .form-group {
      text-align: left;
    }
  }
  .arrowMenuToggle {
    cursor: pointer;
    @media (max-width: 600px) {
      position: absolute;
    }
  }
  .eligibility-modal-header {
    font-size: 26px;
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Demibold);
  }
  .eligibility-modal-Subheader {
    font-size: 18px;
  }
  h6 {
    // margin: 3% 22%;
    line-height: 1.5;
    @media (max-width: 1024px) {
      // margin: 3% 15%;
    }
    @media (max-width: 600px) {
      // margin: 3% 2%;
    }
  }
}
.HomePageLine {
  width: 85px;
  height: 4px;
  border-radius: 2.5px;
  background-color: var(--dusty-orange);
  margin: 20px 0 25px 0;
}
.errorMessage {
  color: var(--danger);
  font-size: 12px;
  font-family: var(--font_family_Medium);
  line-height: 24px;
  text-align: left;
  margin: 5px 5px 0 5px;
}
.arrowMenuToggle {
  cursor: pointer;
}
.eligibility-check-btn {
  background-color: var(--primary_brand_color_light) !important;
  // color: var(----white) !important;
  border-radius: 8px !important;
  font-weight: var(--font_weight_2) !important;
}
.SorryMsg {
  font-size: 26px;
  font-family: var(--font_family_Demibold);
  font-weight: var(--font_weight_2);
}
