.HomePageSignUp {
  // background: url('../../Assets/images/HomePage/signupMain.svg') center repeat;
  background: var(--white);
  object-fit: contain;
  padding: 90px 0;
  margin: 0 20px 0px 20px;
  height: 404px;
  @media (max-width: 600px) {
    padding-top: 0px !important;
    margin-top: 0px;
  }
  @media (max-width: 1024px) {
    height: 332px;
    padding-top: 80px;
  }
  .HomePageTitle {
    text-align: center;
    font-family: var(--font_family_Medium);
    font-size: 30px;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    @media (max-width: 1024px) {
      font-size: 22px;
    }
    @media (max-width: 600px) {
      padding-top: 30px;
    }
  }
  .HomePageSignUpSubTitle {
    margin: 20px 32px 29px 33px;
    // margin: 15px 30% 0px 30%
    font-family: var(--font_family_Medium);
    font-size: 18px;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: var(--subheader-color_1);
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }
  .HomePageLine {
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    margin: 10px auto 10px auto;
    background-color: var(--dusty-orange);
  }
  .HomePageSignUpTxtField {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 25px;

    .threeLine {
      position: relative;
      bottom: 25px;
      left: 15px;

      @media (max-width: 600px) {
        position: relative;
        top: 5px;
        left: -15px;
      }
    }

    .web {
      @media (max-width: 600px) {
        display: none;
      }
    }
    .mobile {
      display: none;
      @media (max-width: 600px) {
        display: unset;
      }
    }

    @media (max-width: 600px) {
      display: block;
    }
    @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
      width: 60%;
    }
    @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
      width: 70%;
    }
    .homeLeadgenInput {
      width: 408px;
      margin-right: 15px;
      @media (max-width: 600px) {
        width: 100%;
      }
      @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
        width: 55%;
      }
      @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
        width: 55%;
      }
    }
    .homeLeadgenBtn {
      width: 187px !important;
      @media (max-width: 600px) {
        margin: 20px 40px;
        // margin-top: 40px;
        width: 50%;
        // margin-left: 25%;
        text-align: center;
      }
      @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
        width: 30%;
      }
      @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
      }
    }
  }
  .HomePageSignUpBtn {
    width: 165px;
    margin: 0 auto;
  }
  .EmailSuccess {
    margin: 0 auto;
    width: fit-content;
    display: flex;
    .emailSuccessImg {
      margin: 0 5px;
      width: 20px;
      height: 20px;
    }
    .emailSuccessText {
      font-family: var(--font_family_Semibold);
      font-size: 18px;
      font-weight: var(--font_weight_2);
    }
  }
  .keepintouchText {
    font-size: 14px;
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Semibold);
    width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  @media (max-width: 1024px) {
    margin: 0 0 50px 0;
    // background: url('../../Assets/images/HomePage/signupIPadPro.svg') center repeat;
  }
  @media (max-width: 768px) {
    // background: url('../../Assets/images/HomePage/signupiPad.svg') center repeat;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 70px 15px;
    // background: url('../../Assets/images/HomePage/signupmobile.svg') center repeat;
    // margin: 0 0 50px 0;
    .HomePageSignUpTxtField {
      width: calc(100% - 40px);
    }
    .keepintouchText {
      width: calc(100% - 30px);
      margin: 0 15px;
    }
  }
}
