@import "../../global/globalStyle.scss";
@import "../../global/globalColorcss.scss";

.homeInternetOuter {
  height: 125px;
  padding: 25px 50px;
  margin-bottom: 100px;
  @media (max-width: 600px) {
    height: 300px;
    padding: 25px 35px;
    text-align: center;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    height: 185px;
  }
  background-color: var(--primary_background);

  .TextField .MuiFilledInput-root {
    width: 400px;
    border: 1px solid var(--white);
    @media (max-width: 600px) {
      width: 90%;
    }
  }
  .TextField .MuiFilledInput-input {
    background: var(--primary_background);
    color: var(--white);
  }
  .TextField .MuiInputLabel-filled {
    color: var(--white);
    @media (max-width: 600px) {
      overflow: inherit;
    }
  }

  .MuiButton-startIcon {
    margin-left: 8px !important;
    .MuiSvgIcon-root {
      font-size: 43px !important;
      font-weight: var(--font_weight_0);
    }
  }

  .homeInternetHeading {
    font-size: 26px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: var(--white);
    font-family: var(--font_family_Demibold);
    @media (max-width: 1200px) {
      font-size: 22px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 1024px) {
      font-size: 22px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 768px) {
      font-size: 22px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 600px) {
      font-size: 22px;
      display: flex;
      text-align: center;
    }
  }
  .homeInternetSubHeading {
    font-size: 18px;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
    color: var(--white);
    // margin: 14px 0;
    font-family: var(--font_family_Medium);
    @media (max-width: 1200px) {
      font-size: 18px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      display: flex;
      justify-content: left;
      margin-top: 0px;
    }
    @media (max-width: 600px) {
      font-size: 14px;
      display: flex;
      text-align: center;
      margin-top: 20px;
    }
  }
  .explore-plan-btn {
    width: 200px;
    float: right;
    margin-top: 10px;

    @media (max-width: 768px) {
      margin-top: 40px;
    }

    @media (max-width: 600px) {
      margin: 30px auto;
      float: none;
    }
  }
}
