/* @font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url("./assets/Fonts/AvenirNext-Semibold.ttf") format("truetype");
  font-weight: bold;
} */
/* @font-face {
  font-family: "AvenirNext-Medium";
  src: local("AvenirNext"),
    url("./assets/Fonts/AvenirNext-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "AvenirNext-Semibold";
  font-weight: 600;
  font-style: normal;
  src: local("AvenirNext"),
    url("./assets/Fonts/AvenirNext-Semibold.ttf") format("truetype");
  font-weight: lighter;
} */

@font-face {
  font-family: 'AvenirNext-Semibold';
  font-weight: bolder;
  src: local("AvenirNext-Semibold"),
   url('./assets/Fonts/AvenirNext-Semibold.ttf') format('truetype'),
  url('./assets/Fonts/AvenirNext-Semibold.woff') format('woff'),
  url('./assets/Fonts/AvenirNext-Semibold.woff2') format('woff2')
} 
@font-face {
  font-family: 'AvenirNext-Demibold';
  font-weight: bolder;
  src: local("AvenirNext-Semibold"),
   url('./assets/Fonts/AvenirNext-Semibold.ttf') format('truetype'),
  url('./assets/Fonts/AvenirNext-Semibold.woff') format('woff'),
  url('./assets/Fonts/AvenirNext-Semibold.woff2') format('woff2')
} 

 @font-face {
  font-family: 'AvenirNext-Medium';
  font-weight: 600;
  src: local("AvenirNext-Medium"),
   url('./assets/Fonts/AvenirNext-Medium.ttf') format('truetype'),
  url('./assets/Fonts/AvenirNext-Medium.woff') format('woff'),
  url('./assets/Fonts/AvenirNext-Medium.woff2') format('woff2')
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "AvenirNext-Semibold", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "AvenirNext";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, "AvenirNext", "AvenirNext-Medium", "AvenirNext-Semibold";
}
