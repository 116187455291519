.OverlayPlan {
  .carouseImage {
    .web {
      padding-right: 0px !important;
    }
  }
  .contentCarousel {
    width: 738px;
    position: absolute;
    right: calc(100% - 57%);
    top: 10%;

    // @media (min-width: 601px) and (max-width: 768px) {
    //   top: 8% !important;
    //   right: calc(100% - 90%);
    // }
    @media (max-width: 600px) {
      top: 8% !important;
    }
    .skeletonRadius {
      border-radius: 25px !important;
    }
    .skeletonLoaderBtn {
      height: 40px !important;
      width: 160px !important;
    }
    .Goodbye-mobile-store {
      font-family: var(--font_family_Demibold);
      font-size: 40px;
      font-weight: var(--font_weight_2);

      text-align: left;
      color: var(--text_color);
      margin-bottom: 0;
      @media (max-width: 600px) {
        font-size: 26px;
        font-family: var(--font_family_Demibold);
        text-align: center !important;
      }
      @media (max-width: 1024px) {
        font-size: 28px;
        font-family: var(--font_family_Demibold);
        text-align: left;
      }
      @media (max-width: 1368px) {
        font-size: 30px;
        font-family: var(--font_family_Demibold);
        text-align: left;
      }
      @media only screen and (min-width: 601px) and (max-width: 992px) and (orientation: portrait) {
        text-align: left;
        font-size: 30px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
        text-align: left;
        font-size: 30px;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1300px) and (orientation: portrait) {
        text-align: left;
        font-size: 40px;
      }
    }
    .ticksMainDiv {
      font-family: var(--font_family_Semibold);
      font-size: 27px;
      text-align: left;
      font-weight: var(--font_weight_2);
      color: var(--text_color);
      margin: 25px 0;
      @media (max-width: 600px) {
        margin: 20px 0;
        font-size: 18px;
        text-align: center;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        text-align: left;
        font-size: 20px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
        text-align: left;
        font-size: 20px;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1300px) and (orientation: portrait) {
        text-align: left;
        font-size: 24px;
      }
    }
    .getStartedBtn {
      display: flex;
      // justify-content: center;
      // text-align: left;
      width: 205px;
      height: 50px;
      // margin: auto;
      .explore-plan-btn {
        background-color: var(--primary_color);
        color: var(--white);
        font-weight: var(--font_weight_4);
        font-size: 22px !important;
      }
      @media (max-width: 600px) {
        margin: 0 auto;
        width: 205px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
        width: 205px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
        width: 205px;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1300px) and (orientation: portrait) {
        width: 205px;
      }
    }

    @media (max-width: 600px) {
      width: 80%;
      left: calc(80% - 70%);
      top: 10%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: 100%;
      left: 0%;
      margin-left: 30px;
      top: 5%;
      text-align: left;
    }
    @media only screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
      width: 100%;
      left: 0%;
      margin-left: 30px;
      top: 5%;
      text-align: left;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1300px) and (orientation: portrait) {
      width: 100%;
      // left: calc(65% - 45%);
      top: 5%;
      text-align: left;
    }
  }
  .carouseImage {
    position: relative;
    // min-height: 595px;
    .web {
      @media (max-width: 1024px) {
        display: none;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    .iPad {
      display: none;
      @media (max-width: 1024px) {
        display: none;
      }
      @media (max-width: 768px) {
        display: unset;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    .iPadPro {
      display: none;
      @media (min-width: 769px) and (max-width: 1024px) {
        display: unset;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    // .iPad {
    //   display: none;
    //   @media (max-width: 768px) {
    //     display: unset;
    //   }
    //   @media (max-width: 600px) {
    //     display: none;
    //   }
    // }
    .mobile {
      display: none;
      @media (max-width: 600px) {
        display: unset;
        width: 100%;
      }
    }
  }
}
