:root {
  --font_family: "AvenirNext-Semibold";
  --font_family_Medium: "AvenirNext-Medium";
  // --font_family_Semibold: "AvenirNext-Semibold";
  // --font_family_Demibold: "AvenirNext-Demibold";
  --font_family_Semibold: "AvenirNext-Semibold";
  --font_family_Demibold: "AvenirNext-Demibold";
  --font_weight_0: 400;
  --font_weight_1: 500;
  --font_weight_2: 600;
  --font_weight_3: 900;
  --font_weight_4: bold;
  --font_weight_5: 700;
}
